.delete {
  min-height: calc(100vh - 435px);
  &__h1 {
    padding-top: 200px;
    font-size: 48px;
  }
  &__container {
    margin-top: 45px;
    padding-top: 30px;
    padding-bottom: 100px;
    margin-left: 30%;
    margin-right: 30%;
    text-align: justify;
  }
}
