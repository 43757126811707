.button {
  background-color: white;
  outline: 2px solid orange;
  transition: all 300ms ease-in;
  padding: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: orange;
    color: white;
  }
}
