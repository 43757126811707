.noscript {
  width: 100%;
  padding: 10px 0;
  background: red;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  color: white;
  text-align: center;
}
