.tarif {
  &__bg {
    position: relative;
    z-index: 2;
    margin-top: 80px;
    &__img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
  &__packs {
    &__head {
      padding-top: 80px;
      margin: 0px 10% 0px 10%;
      display: flex;
      justify-content: space-between;

      &__left {
        &__h1 {
          color: black;
          width: 200px;
          margin: 0px 0px 30px 0px;
        }
        &__p {
          width: 70%;
          line-height: 1.4;
        }
        &__offer {
          width: 90%;
          line-height: 1.4;
          text-align: center;
          background-color: orange;
          padding: 10px;
          margin-top: 20px;
          border-radius: 10px;
          animation: updown 2s infinite;
        }
      }
      &__right {
        &__img {
          position: relative;
          z-index: 3;
          object-fit: cover;
          width: 250px;
          height: 150px;
          border-radius: 50%;
          shape-outside: circle();
          margin-right: 60px;
          border: 5px solid aqua;
        }
        &__p {
          position: relative;
          top: -75px;
          line-height: 1.4;
          text-align: center;
          padding: 75px 10px 10px 10px;
          z-index: 1;
          width: 250px;
          background-color: aqua;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
    &__container {
      margin: 0px 10%;
      padding-bottom: 50px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
      &__card {
        padding: 20px 30px;
        border-radius: 30px;
        box-shadow: inset 0px 0px 10px 5px black;
        height: 100%;
        &__h3 {
          line-height: 36px;
          text-align: center;
          width: fit-content;
          color: white;
          padding: 10px 20px;
          margin: 0px auto 30px auto;
        }
        &__ul {
          list-style-type: none;
          line-height: 1.4;

          &__li {
            position: relative;
            color: white;
            padding-left: 30px;
            margin-bottom: 5px;
            &__icone {
              position: absolute;
              left: -15px;
              top: -5px;
            }
          }
        }
        &__div {
          text-align: center;
          margin: 30px 0px;
        }
        &__price {
          text-align: center;
          font-size: 36px;
          margin: 80px auto 40px auto;
          color: white;
          font-weight: 800;
          &__sign {
            position: relative;
            top: -10px;
            left: 5px;
            font-size: 20px;
          }
        }
        &__unique {
          background-color: rgb(182 0 0);
          border: 3px solid rgba(255, 0, 0, 0.339);
          margin-bottom: 30px;
        }
        &__flash {
          background-color: rgb(128 50 170);
          border: 3px solid rgba(255, 166, 0, 0.556);
        }
        &__long {
          background-color: rgb(0 104 0);
          border: 3px solid rgba(0, 128, 0, 0.278);
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .tarif {
    &__packs {
      &__container {
        grid-template-columns: 1fr;
        &__card {
          &__price {
            margin: 40px auto 0px auto;
          }
          &__unique {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) and (max-width: 1154px) {
  .tarif {
    &__packs {
      &__container {
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .tarif {
    &__packs {
      &__head {
        display: block;
        &__left {
          &__h1 {
            margin: 0px auto 30px auto;
            width: 150px;
          }
          &__p {
            width: 100%;
          }
          &__offer {
            width: 100%;
          }
        }
        &__right {
          margin-top: 30px;
          text-align: center;
          &__img {
            margin-right: 0px;
          }
          &__p {
            margin: auto;
          }
        }
      }
      &__container {
        &__card {
          &__h3 {
            padding: 10px 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tarif {
    &__packs {
      &__head {
        margin: 0px 5% 0px 5%;
        padding-top: 40px;
      }
      &__container {
        margin: 0px 5%;
        &__card {
          &__h3 {
            padding: 10px 0px;
          }
          &__ul {
            padding-left: 10px;
            &__li {
              position: relative;
              &__icone {
                position: absolute;
                left: -25px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .tarif {
    &__bg {
      margin-top: 74px;
    }
  }
}

@keyframes updown {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
