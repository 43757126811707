.paging {
  clear: both;
  display: flex;
  justify-content: space-between;
  &__p {
    align-self: center;
  }
  &__div {
    display: flex;
    &__span {
      padding: 0.5em 1em;
      display: flex;
      align-content: center;
      flex-direction: column;
      justify-content: center;
      outline: 1px solid aqua;
      border-radius: 5px;
      cursor: pointer;
      transition: all 400ms;
      margin-left: 0.5em;
      &:hover {
        color: white;
        background: aqua;
      }
    }
    &__div {
      display: flex;
      &__span {
        padding: 0.5em 1em;
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        &:hover {
          color: white;
          border-radius: 2px;
          background: aqua;
        }
        &__current {
          color: white;
          padding: 0.5em 1em;
          display: flex;
          align-content: center;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          border-radius: 5px;
          margin: 0px 0.5em;
          background: orange;
        }
        &__point {
          padding: 0px 0.5em 8px 0.5em;
          display: flex;
          align-content: center;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .paging {
    display: block;
    &__p {
      text-align: center;
      margin: 10px 0px;
    }
    &__div {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 550px) {
  .paging {
    &__div {
      display: block;
      &__span {
        text-align: center;
      }
      &__div {
        justify-content: center;
      }
    }
  }
}
