.ul {
  background-color: aqua;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  list-style-type: decimal;
  padding: 20px 0px 20px 40px;
  line-height: 1.4;
  &__li {
    &__link {
      &:hover {
        text-decoration: underline;
      }
    }
    &__ul {
      padding: 0px 0px 0px 40px;
      &__li {
        &__link {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ul {
    padding: 20px 0px 20px 30px;
    &__li {
      &__ul {
        padding: 0px 0px 0px 20px;
      }
    }
  }
}
