.contact {
  &__bg {
    position: relative;
    z-index: 2;
    margin-top: 80px;
    &__img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
  &__main {
    margin: 0px 10%;
    &__h1 {
      color: black;
      padding-top: 80px;
      width: 223px;
      margin: 0px auto 40px auto;
    }
    &__p {
      margin-bottom: 30px;
      line-height: 1.4;
    }
    &__container {
      margin-bottom: 50px;
      &__content {
        display: flex;
        justify-content: space-between;
        &__card {
          width: 47%;
          padding: 20px 40px;
          border-radius: 10px;
          box-shadow: 0px 0px 14px 5px aqua;
          margin-bottom: 40px;
          font-size: 18px;
          &__link {
            color: black;
            text-decoration: none;
            transition: all 400ms;
            &:hover {
              color: aqua;
            }
          }
        }
      }
      &__form {
        width: 100%;
        margin-top: -10px;
        display: flex;
        flex-direction: column;
        &__submit {
          margin-top: 20px;
          cursor: pointer;
          border: 1px solid orange;
          padding: 10px;
          background-color: white;
          border-radius: 10px;
          color: black;
          transition: all 400ms;
          position: relative;
          z-index: 1;
          height: unset;
          &:hover {
            transform: scale(1.1);
            background-color: orange;
            color: white;
          }
          &__load {
            border-radius: 10px;
            border: 2px solid orange;
            background-color: white;
            padding: 0px 10px;
            display: flex;
            margin-top: 20px;
            color: black;
            &__span {
              align-self: center;
            }
            &__arc {
              position: relative;
              bottom: 9px;
              margin: 18px 10px 0px 10px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border-top: 1px solid black;
              border-bottom: 1px solid transparent;
              border-left: 1px solid black;
              border-right: 1px solid transparent;
              animation: load 2000ms both infinite ease-out;
              &__circle {
                position: relative;
                right: -13px;
                width: 5px;
                height: 5px;
                background-color: black;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .contact {
    &__main {
      &__container {
        &__content {
          display: block;
          &__card {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .contact {
    &__main {
      &__container {
        &__content {
          &__card {
            padding: 20px 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contact {
    &__main {
      margin: 0px 5%;
      &__h1 {
        padding-top: 40px;
        width: 180px;
      }
      &__container {
        &__content {
          &__card {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .contact {
    &__bg {
      margin-top: 74px;
      &__img {
        height: 200px;
      }
    }
    &__main {
      &__container {
        &__content {
          &__card {
            &__p {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(680deg);
  }
}
