.article {
  &__author {
    margin-left: 20px;
    margin-top: 40px;
    font-style: italic;
  }
  &__banner {
    margin-top: 84px;
    position: relative;
    &__img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
    &__h1 {
      width: 80%;
      margin: 30px auto 50px auto;
      font-size: clamp(38px, 4vw, 48px);
    }
  }

  &__container {
    padding-top: 30px;
    /* padding-bottom: 60px; */
    margin-left: 0%;
    margin-right: 0%;
    &__left {
      margin-left: 10%;
      margin-right: 10%;
      width: 80%;
      //width: 70%;
      padding-right: 20px;
      &__sommaire {
        border: 1px solid aqua;
        box-shadow: 2px 2px 2px aqua;
        position: relative;
        z-index: 2;
        background: white;
        margin: 40px 0px 20px 0px;
        border-radius: 10px;
        width: 100%;
        transition: all 400ms ease-in;
        &__img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
          transition: all 400ms ease-in;
          &__up {
            transform: rotate(180deg) translateY(-50%);
            transform-origin: top;
          }
          &__down {
            transform: rotate(0deg) translateY(-50%);
            transform-origin: top;
          }
        }
        &__p {
          background-color: aqua;
          z-index: 1;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          position: relative;
          padding: 20px 10px 10px 10px;
          line-height: 1.4;
        }
      }
      &__link {
        text-decoration: none;
        margin-bottom: 10px;
        cursor: pointer;
        border: 1px solid orange;
        padding: 5px 10px;
        background-color: white;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: orange;
        }
      }
      &__author {
        margin-top: 30px;
        font-style: italic;
      }
      &__desc {
        margin-top: 20px;
        line-height: 1.4;
      }
      &__line {
        width: 80%;
        margin: 50px auto 50px auto;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.3);
      }
      &__footer {
        padding: 60px 20% 60px 20%;
        background-color: rgba(0, 0, 0, 0.04);
        width: 100%;
        margin: 50px auto 0px auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__img {
          width: 150px;
          height: 150px;
          object-fit: cover;
        }
        &__p {
          margin-left: 30px;
        }
      }
      &__contact {
        width: 80%;
        margin: 0px auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 40px;
        justify-items: center;
        row-gap: 40px;
        &__link {
          cursor: pointer;
          &__img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            transition: transform 0.3s;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
  &__last {
    margin: 40px 0px;
    position: relative;
    &__container {
      position: relative;
      padding: 0px 10% 0px 10%;
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 40px;
      column-gap: 40px;
      grid-row-gap: 40px;
      row-gap: 40px;
    }
    &__card {
      min-height: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 7px 4px aqua;
      display: block;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      &__div {
        padding: 10px;
        &__btn {
          display: inline-block;
          margin: 20px auto 0px auto;
          cursor: pointer;
          border: 2px solid orange;
          padding: 10px 20px;
          background-color: white;
          color: black;
          text-decoration: none;
          border-radius: 10px;
          position: relative;
          left: 50%;
          transform: translate(-50%);
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: orange;
            transform: translate(-50%) scale(1.05);
            color: black;
          }
        }
      }

      &__img {
        min-height: 100%;
        display: inline-block;
        vertical-align: middle;
        transform: scale3d(1, 1, 1);
        transition: transform 400ms ease-in-out;
        object-fit: cover;
        height: auto;
        border-radius: 10px;
        cursor: pointer;
        width: 100%;
        min-width: 400px;
        &:hover {
          transform: scale(1.1);
          transition: transform 400ms ease-in-out;
        }
      }
      &:hover {
        transform: scale(1.05);
        box-shadow: 0px 0px 14px 8px aqua;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .article {
    &__banner {
      margin-top: 74px;
      &__img {
        height: 200px;
      }
      &__h1 {
        width: 100%;
      }
    }
    &__container {
      &__left {
        margin-left: 5%;
        margin-right: 5%;
        width: unset;
        //width: 70%;
        &__footer {
          flex-direction: column;
          &__img {
            width: 150px;
            height: 150px;
            object-fit: cover;
          }
          &__p {
            margin-left: 0;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
