.button {
  border: none;
  color: black;
  cursor: pointer;
  font-size: 18px;
  background-color: transparent;
  font-style: normal;
  text-decoration: underline;
  transition: all 300ms ease-in;
  &:hover {
    color: rgb(161, 161, 161);
  }
}

@media screen and (max-width: 768px) {
  .button {
    font-size: 16px;
  }
}
