.bg {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.9);
  position: fixed;
  z-index: 998;
  top: 0;
}

.login {
  background-color: white;
  box-shadow: 0px 0px 8px 8px aqua;
  border-radius: 20px;
  padding: 50px 30px;
  width: 50%;
  position: fixed;
  left: 50%;
  top: 50%;
  max-height: 90%;
  overflow: scroll;
  z-index: 999;
  &__h1 {
    color: black;
    margin: 40px 0px;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  &__btn {
    cursor: pointer;
    float: right;
    position: absolute;
    right: 28px;
    top: 25px;
    border: none;
    background-color: orange;
    border-radius: 5px;
    transition: all 400ms;
    padding: 5px;
    &:hover {
      transform: scale(1.05);
    }
    &:hover > &__img {
      transform: rotate(90deg);
    }
    &__img {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 400ms;
    }
  }
  &__p {
    display: flex;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.4;
    &__icone {
      margin-right: 7px;
    }
  }
  &__content {
    font-size: 18px;
    line-height: 1.4;
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
  }
}

@media screen and (max-width: 768px) {
  .login {
    width: 80%;
    padding: 40px 20px;
    &__btn {
      right: 20px;
      top: 22px;
    }
    &__content {
      font-size: 16px;
    }
    &__p {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 425px) {
  .login {
    width: 95%;
    padding: 40px 10px;
    &__btn {
      right: 14px;
      top: 18px;
    }
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(680deg);
  }
}
