.reset {
  &__h1 {
    padding-top: 200px;
    padding-bottom: 30px;
    font-size: 48px;
  }
  &__container {
    margin-top: 45px;
    padding-top: 10px;
    padding-bottom: 100px;
  }
  &__article {
    margin-left: 20%;
    margin-right: 20%;
    text-align: justify;
    background-color: white;
    padding: 20px;
    &__h2 {
      text-align: center;
      margin-top: 50px;
      font-size: 30px;
    }
    &__p {
      margin-top: 30px;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    margin: 20px 0px 40px 0px;
    &__div {
      display: flex;
      flex-direction: column;
    }
    &__input {
      height: 22px;
      font-size: 15px;
      border-radius: 5px;
      border: none;
      &__error {
        color: orange;
        padding-top: 5px;
        font-size: 21px;
      }
    }
    &__submit {
      text-align: center;
      margin: 0px auto;
      &__btn {
        margin-top: 30px;
        cursor: pointer;
        border: 2px solid orange;
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        transition: all 400ms;
        position: relative;
        z-index: 1;
        height: unset;
        transition: all 400ms;
        &:hover {
          transform: scale(1.1);
          background-color: orange;
          color: white;
        }
        &__load {
          border-radius: 10px;
          border: 2px solid orange;
          background-color: white;
          padding: 0px 10px;
          display: flex;
          margin-top: 20px;
          color: black;
          &__span {
            align-self: center;
          }
          &__arc {
            position: relative;
            bottom: 9px;
            margin: 18px 10px 0px 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border-top: 1px solid black;
            border-bottom: 1px solid transparent;
            border-left: 1px solid black;
            border-right: 1px solid transparent;
            animation: load 2000ms both infinite ease-out;
            &__circle {
              position: relative;
              right: -13px;
              width: 5px;
              height: 5px;
              background-color: black;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(680deg);
  }
}
