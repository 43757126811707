.loadData {
  text-align: center;
  &__arc {
    margin: 20px auto 0px auto;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-top: 1px solid black;
    border-bottom: 1px solid transparent;
    border-left: 1px solid black;
    border-right: 1px solid transparent;
    animation: load 2000ms both infinite ease-out;
    &__circle {
      position: absolute;
      top: 17px;
      right: 22px;
      width: 10px;
      height: 10px;
      background-color: black;
      border-radius: 50%;
    }
  }
}

.arc {
  width: 15px;
  position: relative;
  top: 2px;
  left: 4px;
  height: 15px;
  margin-left: 5px;
  border-radius: 50%;
  border-top: 1px solid black;
  border-bottom: 1px solid transparent;
  border-left: 1px solid black;
  border-right: 1px solid transparent;
  animation: load 2000ms both infinite ease-out;
  &__circle {
    position: relative;
    right: -9px;
    top: 0px;
    width: 5px;
    height: 5px;
    background-color: black;
    border-radius: 50%;
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(680deg);
  }
}

.content {
  width: 100%;
  margin: auto 5%;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  &__flex {
    display: flex;
    justify-content: space-around;
    margin: 40px 0px;
    &__div {
      &__right {
        width: 60%;
        &__h2 {
          margin-bottom: 20px;
        }
        &__div {
          max-height: 210px;
          overflow: scroll;
          &__table {
            width: 100%;
          }
        }
      }
      &__left {
        padding: 10px;
        box-shadow: 0px 0px 14px 5px aqua;
        border-radius: 20px;
        padding-bottom: 20px;
        height: fit-content;
        &__h2 {
          margin-bottom: 20px;
          background-color: orange;
          padding: 10px;
          color: white;
          border-radius: 10px;
        }
        &__ul {
          list-style-type: none;
          &__li {
            margin-bottom: 5px;
            &__flex {
              display: flex;
            }
            &__span {
              font-weight: 600;
            }
          }

          &__ul {
            list-style-type: none;
            &__li {
              margin-bottom: 5px;
              margin-left: 20px;
            }
          }
          &__div {
            &__btn {
              padding: 10px;
              cursor: pointer;
              background-color: white;
              border: none;
              border-radius: 10px;
              outline: 2px solid orange;
              &:hover {
                background-color: orange;
                color: white;
              }
              &__load {
                border-radius: 10px;
                border: 2px solid orange;
                background-color: white;
                padding: 0px 10px;
                display: flex;
                color: black;
                &__span {
                  align-self: center;
                }
                &__arc {
                  position: relative;
                  bottom: 9px;
                  margin: 18px 10px 0px 10px;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  border-top: 1px solid black;
                  border-bottom: 1px solid transparent;
                  border-left: 1px solid black;
                  border-right: 1px solid transparent;
                  animation: load 2000ms both infinite ease-out;
                  &__circle {
                    position: relative;
                    right: -13px;
                    width: 5px;
                    height: 5px;
                    background-color: black;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.datatable {
  &__container {
    &__div {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .content {
    &__flex {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 40px 0px;
      &__div {
        &__left {
          margin-bottom: 60px;
        }
        &__right {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .datatable {
    &__container {
      &__div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .content {
    margin: auto 2%;
    padding: 0px;
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(680deg);
  }
}
