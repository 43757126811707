.display {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  &__arc {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 18px 0px 0px 0px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 1px solid black;
    border-bottom: 1px solid transparent;
    border-left: 1px solid black;
    border-right: 1px solid transparent;
    animation: load 2000ms both infinite ease-out;
    &__circle {
      position: relative;
      right: -13px;
      width: 5px;
      height: 5px;
      background-color: black;
      border-radius: 50%;
    }
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(680deg);
  }
}
